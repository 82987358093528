import Vue from "vue";
import FetchService from "./fetch-service.js";

const vue = new Vue();

class ExtratoService extends FetchService {

    listarFiltros(clienteId) {
        return {
            clienteId: clienteId,
            servicosIds: [],
            pontoMinimo: null,
            pontoMaximo: null,
            dataInicial: null,
            dataFinal: null,
            ordenarPor: 3,
            ordem: 1,
            pagina: 1,
            itensPagina: 10000,
        };
    }

    async listarCampos() {
        return [
            {
                descricao: "",
                valor: "servicoIcone",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "icone",
                ordenado: null,
                ocultarResponsivo: false,
            },
            {
                descricao: "Serviço",
                valor: "servico",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: false,
            },
            {
                descricao: "Pontos",
                valor: "pontoFormatado",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: false,
            },
            {
                descricao: "Data",
                valor: "dataFormatada",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: false,
                ocultarResponsivo: true,
            },
        ]
    }

    async listarExtrato(filtro) {
        const route = `${vue.$globals.endpoint}clienteextrato/listarClienteExtratos`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            filtro, 
            false, 
            route
        );
    }

    async exportarExtratoExcel(filtro) {
        const route = `${vue.$globals.endpoint}clienteextrato/exportarClienteExtratosExcel`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            filtro, 
            true, 
            route
        );
    }

    async cadastrar(obj) {
        const route = `${vue.$globals.endpoint}clienteextrato`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            obj, 
            false, 
            route
        );
    }

    async atualizar(obj) {
        const route = `${vue.$globals.endpoint}extrato`;
        return await this.fetchResponse(
            "PUT", 
            vue.$globals.headerPadrao, 
            obj, 
            false, 
            route
        );
    }

    async excluir(id) {
        const route = `${vue.$globals.endpoint}clienteextrato?id=${id}`;
        return await this.fetchResponse(
            "DELETE", 
            vue.$globals.headerPadrao, 
            null, 
            false, 
            route
        );
    }

    async getTotalGeralByClienteId(id) {
        const route = `${vue.$globals.endpoint}clienteextrato/getTotalGeralByClienteId?clienteId=${id}`;
        return await this.fetchResponse(
            "GET", 
            vue.$globals.headerPadrao, 
            null, 
            false, 
            route
        );
    }
}

export default ExtratoService;