<template>
  <div @click="handleFocusOut($event)" class="admin-app-container">
    <Loading v-if="loading" />
    <Header />
    <div class="pt-1 pa-sm-6 app-container">
      <h2 class="pl-2 pr-2 pa-sm-2 text-center">Cadastro de Clientes</h2>
      <Grid
        :key="gridKey"
        class="grid-component"
        ref="gridComponent"
        :fields="fields"
        :list="list"
        :filters="filters"
        :defaultInsertObject="defaultInsertObject"
        gridType="responsive"
        filterType="search"
        gridOverflow="vertical"
        :gridResizable="false"
        @listarItens="listarItens($event)"
        @selecionarItem="selecionarItem($event)"
        @exportarExcel="exportarExcel($event)"
        @salvarItem="salvarItem()"
        @botaoClick="botaoClick($event)"
        :minimizedItemList="[]"
        gridTableId="grid-admin-cliente"
        :hideInsert="false"
        :customButtonsList="customButtonsList"
        :hasCustomButtons="true"
        @customButtonClick="customButtonClick()"
      />
      <Paginacao
        id="paginacao"
        :totalPaginas="totalPages"
        :paginaAtual="filters.pagina"
        :totalItens="totalItems"
        @alterarItensPorPagina="alterarItensPorPagina($event)"
        @alterarPagina="alterarPagina($event)"
      />
      <ModalSucesso
        :mensagem="mensagemAlerta"
        :alertaSucesso="alertaSucesso"
        :titulo="mensagemTitulo"
        @ocultarSucesso="ocultarSucesso"
      />
      <MensagemErro
        :mensagemAlerta="mensagemAlerta"
        :alertaErro="alertaErro"
        @ocultarErro="alertaErro = false"
        :login="false"
        :timeout="3000"
      />
      <v-row justify="center" v-if="item">
        <v-dialog v-model="modalAdmin" fullscreen>
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>Cadastro de Cliente</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="closeModalAdmin">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="pl-0 pt-2 pr-0 pb-0">
              <v-tabs>
                <v-tab>
                  <v-icon left color="secondary"> mdi-account </v-icon>
                  <span class="d-none d-sm-flex">Dados cadastrais</span>
                </v-tab>
                <v-tab :disabled="!item.clienteId">
                  <v-icon left color="secondary"> mdi-share-variant </v-icon>
                  <span class="d-none d-sm-flex">Indicações</span>
                </v-tab>
                <v-tab :disabled="!item.clienteId">
                  <v-icon left color="secondary">
                    mdi-tag-multiple-outline
                  </v-icon>
                  <span class="d-none d-sm-flex">Extrato</span>
                </v-tab>

                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-form ref="formAdmin" class="pt-4">
                        <v-row class="pt-2 pb-2">
                          <v-col cols="12" xs="12" class="pa-0 pa-sm-1">
                            <Thumb
                              :item="item"
                              @selecionarImagem="selecionarImagem($event)"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            xs="12"
                            sm="6"
                            md="4"
                            class="pa-0 pa-sm-1"
                            v-if="item.clienteId"
                          >
                            <v-text-field
                              ref="refOrigem"
                              label="Origem do Cadastro"
                              dense
                              v-model="item.origemCadastro"
                              clearable
                              outlined
                              disabled
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            xs="12"
                            sm="6"
                            md="4"
                            class="pa-0 pa-sm-1"
                            v-if="item.clienteId"
                          >
                            <v-text-field
                              ref="refDataAprovacao"
                              label="Data Aprovação"
                              dense
                              v-model="item.dataAprovacaoFormatada"
                              disabled
                              clearable
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            xs="12"
                            sm="6"
                            md="4"
                            class="pa-0 pa-sm-1"
                            v-if="item.clienteId"
                          >
                            <v-text-field
                              ref="refClienteStatus"
                              label="Status"
                              dense
                              v-model="item.clienteStatus"
                              disabled
                              clearable
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            xs="12"
                            sm="6"
                            md="4"
                            class="pa-0 pa-sm-1"
                          >
                            <v-text-field
                              ref="refNome"
                              label="Nome"
                              dense
                              v-model="item.nome"
                              clearable
                              outlined
                              :rules="[
                                (v) => !!v || 'O campo nome é obrigatório',
                              ]"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            xs="12"
                            sm="6"
                            md="4"
                            class="pa-0 pa-sm-1"
                          >
                            <v-text-field
                              ref="refTelefone"
                              label="Telefone"
                              dense
                              v-model="item.telefone"
                              clearable
                              outlined
                              v-mask="['+## #####-######']"
                              :rules="[
                                (v) =>
                                  !!v || 'O campo quem telefone é obrigatório',
                              ]"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            xs="12"
                            sm="6"
                            md="4"
                            class="pa-0 pa-sm-1"
                          >
                            <v-text-field
                              ref="refEmail"
                              label="E-mail"
                              dense
                              v-model="item.email"
                              clearable
                              outlined
                              :rules="[
                                (v) => !!v || 'O campo e-mail é obrigatório',
                              ]"
                              :error-messages="item.emailDuplicado"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            xs="12"
                            sm="6"
                            md="4"
                            class="pa-0 pa-sm-1"
                          >
                            <v-text-field
                              ref="refInstagram"
                              label="Instagram"
                              dense
                              v-model="item.instagram"
                              clearable
                              outlined
                              :rules="[
                                (v) => !!v || 'O campo instagram é obrigatório',
                              ]"
                            />
                          </v-col>
                          <v-col cols="12"
                            xs="12"
                            sm="6"
                            md="4" class="pa-0 pa-sm-1">
                            <v-select
                              v-model="item.ativo"
                              item-text="text"
                              item-value="value"
                              :items="[
                                {value: true, text: 'Ativo'},
                                {value: false, text: 'Inativo'}
                              ]"
                              attach
                              label="Ativo"
                              dense
                              outlined
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                    <v-card-actions class="pt-0">
                      <v-spacer></v-spacer>
                      <v-btn
                        color="red darken-1"
                        text
                        right
                        class="text-caption"
                        @click="closeModalAdmin"
                      >
                        <v-icon class="text-caption">mdi-close</v-icon>Cancelar
                      </v-btn>
                      <v-btn
                        color="blue"
                        text
                        class="text-caption"
                        @click="salvarItem"
                      >
                        <v-icon class="text-caption"
                          >mdi-content-save-outline</v-icon
                        >
                        {{ item.clienteId ? "Salvar" : "Inserir" }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="pa-0">
                      <AprovacaoCliente :cliente="item" />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="pa-0">
                      <ClienteExtrato :cliente="item" />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="modalIndicacao" max-width="600px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>Refer a friend</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="closeModalIndicacao">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="pa-4">
              <h2 class="mb-4">Customer indication</h2>
              <p class="title">
                Share the link and make it possible for new customers to be part of the points program.
              </p>
              <p class="title">
                The new registrations will be sent to the "Approval" area.
              </p>
              <hr>
              <div class="d-flex justify-center mt-2">
                <v-btn
                  color="blue"
                  text
                  class="text-caption"
                  @click="compartilharLink"
                >
                  <v-icon class="text-caption">mdi-share-variant</v-icon>
                  Share
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loading.vue";
import Header from "../../components/header.vue";
import Paginacao from "../../components/paginacao.vue";
import Grid from "../../components/grid.vue";
import ClienteService from "../../service/cliente-service.js";
import ExcelService from "../../service/excel-service.js";
import ModalSucesso from "../../components/modal-sucesso.vue";
import MensagemErro from "../../components/mensagem-erro.vue";
import Thumb from "../../components/thumb.vue";
import { mask } from "vue-the-mask";
import AprovacaoCliente from "./aprovacao-cliente.vue";
import ClienteExtrato from "./cliente-extrato.vue";
import ImageService from "../../service/image-service.js";

const imageService = new ImageService();
const clienteService = new ClienteService();
const excelService = new ExcelService();

export default {
  directives: {
    mask,
  },
  components: {
    Loading,
    Header,
    Paginacao,
    Grid,
    ModalSucesso,
    MensagemErro,
    Thumb,
    AprovacaoCliente,
    ClienteExtrato,
  },
  data() {
    return {
      mensagemTitulo: "",
      mensagemAlerta: "",
      alertaSucesso: false,
      alertaErro: false,
      loading: false,
      filters: clienteService.listarFiltros(),
      modalAdmin: false,
      item: null,
      totalPages: 1,
      totalItems: 1,
      fields: [],
      list: [],
      defaultInsertObject: {
        id: null,
        nome: null,
        email: null,
        telefone: null,
        instagram: null,
      },
      aprovacao: null,
      modalAprovacao: false,
      customButtonsList: [
        {
          function: "indicar",
          customButtonIcon: "mdi-share-variant",
          customButtonDescription: "Indicar",
          color: "primary",
        },
      ],
      extratoList: [],
      modalIndicacao: false,
      linkCompartilhamento: null,
      gridKey: 0,
    };
  },
  methods: {
    compartilharLink() {
      if (navigator.share !== undefined) {
        navigator.share({
          title: "Refer a Friend",
          text: "Click on the link and register",
          url: this.linkCompartilhamento,
        });
        this.modalIndicacao = false;
      }
    },
    closeModalIndicacao() {
      this.loading = false;
      this.modalIndicacao = false;
    },
    async customButtonClick() {
      this.loading = true;
      let result = await clienteService.linkCompartilhamento(
        JSON.parse(localStorage.getItem("login")).clienteId,
        window.location.origin
      );
      if (result?.statusCode === 200) {
        this.modalIndicacao = true;
        this.linkCompartilhamento = result?.data.mensagem;
        this.loading = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loading = false;
      }
    },
    selecionarImagem(callback) {
      if (callback.success) {
        this.item.foto = callback.data.base64;
        this.item.nomeArquivo = callback.data.name;
        this.item.base64String = callback.data.base64;
      } else {
        this.mensagemAlerta = callback.data;
        this.alertaErro = true;
      }
    },
    handleFocusOut(event) {
      this.$refs.gridComponent?.handleFocusOut(event);
    },
    async exportarExcel(filtros) {
      this.loading = true;

      let result = await clienteService.exportarClientesExcel(filtros);
      if (result?.statusCode === 200) {
        excelService.downloadFile(result?.data, "Clientes.xlsx");
        this.loading = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loading = false;
      }
    },
    selecionarItem(item) {
      this.item = JSON.parse(JSON.stringify(item));
      this.modalAdmin = true;
    },
    async salvarItem() {
      if (this.$refs.formAdmin.validate()) {
        this.loading = true;

        let novoItem = { ...this.item };

        novoItem.ponto = 0;
        novoItem.admin = false;
        novoItem.primeiroAcesso = true;
        novoItem.dataCadastro = null;
        novoItem.clienteStatus = null;
        novoItem.usuario = null;
        novoItem.origemCadastro = null;
        novoItem.clienteIndicacaoId = JSON.parse(
          localStorage.getItem("login")
        ).clienteId;

        let result = novoItem.clienteId
          ? await clienteService.atualizar(novoItem)
          : await clienteService.cadastrar(novoItem);
        if (result?.statusCode === 200) {
          if (this.item.clienteId) {
            this.mensagemAlerta =
              "O cliente '" + this.item.nome + "' foi editado com sucesso";
            this.mensagemTitulo = "Edição de cliente";
          } else {
            this.mensagemAlerta =
              "O cliente '" + this.item.nome + "' foi inserido com sucesso";
            this.mensagemTitulo = "Inserção de cliente";
          }
          this.alertaSucesso = true;
          this.closeModalAdmin();
          this.$refs.formAdmin.resetValidation();
        } else if (result?.statusCode === 409) {
          this.item.emailDuplicado = result?.data.mensagem;
          this.$refs.refEmail.focus();
          this.loading = false;
        } else {
          this.mensagemAlerta = result?.data.mensagem;
          this.alertaErro = true;
          this.loading = false;
        }
      } else {
        if (!this.item.data) this.$refs.refData.setFocus();
        else if (!this.item.quemIndicou) this.$refs.refQuemIndicou.focus();
        else if (!this.item.nome) this.$refs.refNome.focus();
        else if (!this.item.telefone) this.$refs.refTelefone.focus();
        else if (!this.item.email) this.$refs.refEmail.focus();
        else if (!this.item.instagram) this.$refs.refInstagram.focus();
      }
    },
    alterarPagina(pagina) {
      this.filters.pagina = pagina;
      this.listarItens();
    },
    alterarItensPorPagina(itens) {
      this.filters.itensPagina = itens;
      this.filters.pagina = 1;
      this.listarItens();
    },
    async listarItens() {
      this.loading = true;
      let result = await clienteService.listarClientes(this.filters);
      if (result?.statusCode === 200) {
        this.list = result?.data.lista;
        this.list.forEach(async (x) => {
          const foto = await imageService.validateImage(x.foto);
          x.foto = foto.status == 404 ? null : foto.url;
          this.gridKey += 1;
        });
        this.totalPages = result?.data.paginas;
        this.totalItems = result?.data.totalItens;
        this.loading = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loading = false;
      }
    },
    closeModalAdmin() {
      this.loading = false;
      this.modalAdmin = false;
      this.item = null;
      const appContainer = document.querySelectorAll(".app-container")[0];
      appContainer.click();
    },
    ocultarSucesso() {
      this.alertaSucesso = false;
      this.loading = false;
      this.listarItens();
    },
    botaoClick(item) {
      this.item = item;
      this.modalSenha = true;
    },
  },
  created: async function () {
    this.fields = await clienteService.listarCampos();
    this.listarItens();
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
};
</script>

<style scoped>
.grid-component {
  max-height: calc(100vh - 284px) !important;
}
.admin-app-container {
  height: 100vh;
}
@media (max-width: 600px) {
  .grid-component {
    max-height: calc(100vh - 244px) !important;
  }
}
</style>