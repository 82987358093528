<template>
  <div @click="handleFocusOut($event)" class="admin-app-container">
    <Loading v-if="loading" />
    <div class="pt-1 pa-sm-6 app-container">
      <h2 class="pl-2 pr-2 pa-sm-2 text-center">
        Extrato - {{ cliente.nome }}
      </h2>
      <Grid
        class="grid-component-modal"
        ref="gridComponent"
        :fields="fields"
        :list="list"
        :filters="filters"
        :defaultInsertObject="defaultInsertObject"
        gridType="responsive"
        filterType="popup"
        gridOverflow="vertical"
        :gridResizable="false"
        @listarItens="listarItens($event)"
        @selecionarItem="selecionarItem($event)"
        @salvarItem="salvarItem()"
        :minimizedItemList="[]"
        gridTableId="grid-admin-extrato"
        :hideInsert="false"
        :customButtonsList="customButtonsList"
        :hasCustomButtons="true"
        @customButtonClick="customButtonClick($event)"
        :hideEdit="true"
        @abrirFiltro="abrirFiltro"
      />
      <hr class="mb-2">
      <div class="pl-2 total-extrato font-weight-bold">
        Saldo atual: {{ totalGeral }} pts
      </div>
      <ModalSucesso
        :mensagem="mensagemAlerta"
        :alertaSucesso="alertaSucesso"
        :titulo="mensagemTitulo"
        @ocultarSucesso="ocultarSucesso"
      />
      <MensagemErro
        :mensagemAlerta="mensagemAlerta"
        :alertaErro="alertaErro"
        @ocultarErro="alertaErro = false"
        :login="false"
        :timeout="3000"
      />
      <v-row justify="center" v-if="item">
        <v-dialog v-model="modalAdmin" max-width="600px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>Adicionar Pontos</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="closeModalAdmin">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <Loading v-if="innerLoading" />
              <v-form ref="formAdmin" class="pt-4">
                <v-row class="pt-2 pb-2">
                  <v-col cols="12" xs="12" class="pa-0 pa-sm-1">
                    <v-autocomplete
                      outlined
                      small-chips
                      v-model="item.servicoId"
                      :items="listaServicos"
                      item-text="descricao"
                      item-value="id"
                      label="Serviço"
                      no-data-text="Nenhum serviço encontrado"
                      clearable
                      dense
                      :rules="[(v) => !!v || 'O campo serviço é obrigatório']"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" xs="12" class="pa-0 pa-sm-1">
                    <DatePicker
                      @selecionarData="selecionarData($event)"
                      :obrigatorio="true"
                      model="data"
                      :retornarHorario="true"
                      :horarioInicial="true"
                      label="Data"
                      :valorInicial="item.data"
                      :ocultarDetalhes="false"
                      refName="refData"
                      ref="refData"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                right
                class="text-caption"
                @click="closeModalAdmin"
              >
                <v-icon class="text-caption">mdi-close</v-icon>Cancelar
              </v-btn>
              <v-btn color="blue" text class="text-caption" @click="salvarItem">
                <v-icon class="text-caption">mdi-content-save-outline</v-icon>
                {{ item.id ? "Salvar" : "Inserir" }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center">
        <v-dialog
          v-model="modalFiltro"
          :fullscreen="windowWidth <= 600"
          max-width="1200px"
        >
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>Filtrar Extrato</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="closeModalFiltro">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <Loading v-if="innerLoading" />
              <v-form ref="formFiltro" class="pt-4">
                <v-row class="pt-2 pb-2">
                  <v-col cols="12" xs="12" class="pa-0 pa-sm-1">
                    <v-autocomplete
                      outlined
                      small-chips
                      v-model="filters.servicosIds"
                      :items="listaServicos"
                      item-text="descricao"
                      item-value="id"
                      label="Serviços"
                      no-data-text="Nenhum serviço encontrado"
                      clearable
                      dense
                      multiple
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="pa-0 pa-sm-1">
                    <v-text-field
                      label="Pontos - mínimo"
                      dense
                      v-model="filters.pontoMinimo"
                      clearable
                      outlined
                      v-mask="['####']"
                      @change="validarPontosFiltro"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="pa-0 pa-sm-1">
                    <v-text-field
                      label="Pontos - máximo"
                      dense
                      v-model="filters.pontoMaximo"
                      clearable
                      outlined
                      v-mask="['####']"
                      @blur="validarPontosFiltro"
                      :error-messages="filters.erroPontoMaximo"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="pa-0 pa-sm-1">
                    <DatePicker
                      @selecionarData="selecionarDataFiltro($event)"
                      :obrigatorio="false"
                      model="dataInicial"
                      :retornarHorario="true"
                      :horarioInicial="true"
                      label="Data Inicial"
                      :ocultarDetalhes="false"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="pa-0 pa-sm-1">
                    <DatePicker
                      @selecionarData="selecionarDataFiltro($event)"
                      :obrigatorio="false"
                      model="dataFinal"
                      :retornarHorario="true"
                      :horarioInicial="false"
                      label="Data Final"
                      :erroPersonalizado="filters.erroDataFinal"
                      :ocultarDetalhes="false"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                right
                class="text-caption"
                @click="closeModalFiltro"
              >
                <v-icon class="text-caption">mdi-close</v-icon>Cancelar
              </v-btn>
              <v-btn
                color="blue"
                text
                class="text-caption"
                @click="validarFiltros"
              >
                <v-icon class="text-caption">mdi-content-save-outline</v-icon>
                Filtrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center" v-if="itemExclusao">
        <v-dialog v-model="modalExclusao" max-width="600px">
          <v-card>
            <v-toolbar dark color="red">
              <v-toolbar-title>Exclusão</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="closeModalExclusao">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="pl-4 pr-4">
              <p class="title pt-2 black--text">
                Deseja realmente excluir o registro selecionado?
              </p>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                right
                class="text-caption"
                @click="closeModalExclusao"
              >
                <v-icon class="text-caption">mdi-close</v-icon>Não
              </v-btn>
              <v-btn
                color="red"
                text
                class="text-caption"
                @click="confirmarExclusao"
              >
                <v-icon class="text-caption">mdi-check</v-icon>Sim
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loading.vue";
import Grid from "../../components/grid.vue";
import ExtratoService from "../../service/extrato-service.js";
import ServicoService from "../../service/servico-service.js";
import ExcelService from "../../service/excel-service.js";
import ModalSucesso from "../../components/modal-sucesso.vue";
import MensagemErro from "../../components/mensagem-erro.vue";
import DatePicker from "../../components/date-picker.vue";
import { mask } from "vue-the-mask";

const extratoService = new ExtratoService();
const servicoService = new ServicoService();
const excelService = new ExcelService();

export default {
  directives: {
    mask,
  },
  props: ["cliente"],
  components: {
    Loading,
    Grid,
    ModalSucesso,
    MensagemErro,
    DatePicker,
  },
  data() {
    return {
      mensagemTitulo: "",
      mensagemAlerta: "",
      alertaSucesso: false,
      alertaErro: false,
      loading: false,
      filters: extratoService.listarFiltros(this.cliente.clienteId),
      modalAdmin: false,
      item: null,
      totalPages: 1,
      totalItems: 1,
      fields: [],
      list: [],
      defaultInsertObject: {
        id: null,
        servicoFormatado: null,
        servicoId: null,
        data: null,
        dataFormatada: null,
      },
      windowWidth: window.innerWidth,
      customButtonsList: [
        {
          function: "remover",
          customButtonIcon: "mdi-trash-can-outline",
          customButtonDescription: "Remover",
          hasSelectedItem: true,
          hasMultipleAction: true,
          color: "primary",
        },
        {
          function: "exportar",
          customButtonIcon: "mdi-file-excel-outline",
          customButtonDescription: "Exportar",
          color: "primary",
        },
      ],
      innerLoading: false,
      modalFiltro: false,
      listaServicos: [],
      totalGeral: 0,
      itemExclusao: null,
      modalExclusao: false,
    };
  },
  methods: {
    async confirmarExclusao() {
      this.modalExclusao = false;
      this.loading = true;
      let result = await extratoService.excluir(this.itemExclusao);
      if (result?.statusCode === 200) {
        this.loading = false;
        this.mensagemAlerta = `Registro excluído com sucesso`;
        this.mensagemTitulo = `Exclusão`;
        this.alertaSucesso = true;
        this.itemExclusao = null;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loading = false;
      }
    },
    closeModalExclusao() {
      this.modalExclusao = false;
    },
    validarPontosFiltro() {
      if (this.filters.pontoMinimo && this.filters.pontoMaximo) {
        const minimo = parseInt(this.filters.pontoMinimo);
        const maximo = parseInt(this.filters.pontoMaximo);
        if (minimo > maximo) {
          this.filters.erroPontoMaximo =
            "O máximo de pontos deve ser superior ou igual ao mínimo";
          this.$forceUpdate();
        } else {
          this.filters.erroPontoMaximo = null;
          this.$forceUpdate();
        }
      }
    },
    abrirFiltro() {
      this.modalFiltro = true;
    },
    closeModalFiltro() {
      this.modalFiltro = false;
    },
    async customButtonClick(item) {
      if (item.function == "exportar") this.exportarExcel();
      else {
        this.itemExclusao = item.ids[0];
        this.modalExclusao = true;
      }
    },
    selecionarData(event) {
      if (this.item) this.item[event.model] = event.data;
    },
    selecionarDataFiltro(event) {
      this.filters[event.model] = event.data;
      this.filters.erroDataFinal = null;
      if (this.filters.dataInicial && this.filters.dataFinal) {
        const dataInicio = new Date(this.filters.dataInicial);
        const dataFim = new Date(this.filters.dataFinal);

        if (dataFim < dataInicio)
          this.filters.erroDataFinal =
            "A data final deve ser superior à inicial";

        this.$forceUpdate();
      }
    },
    handleFocusOut(event) {
      this.$refs.gridComponent?.handleFocusOut(event);
    },
    async exportarExcel() {
      this.loading = true;
      let result = await extratoService.exportarExtratoExcel(this.filters);
      if (result?.statusCode === 200) {
        excelService.downloadFile(result?.data, "Extrato.xlsx");
        this.loading = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loading = false;
      }
    },
    selecionarItem(item) {
      this.item = JSON.parse(JSON.stringify(item));
      this.modalAdmin = true;
    },
    selecionarPontosPorServico(){
      return this.listaServicos.filter(x => {
        return x.id == this.item.servicoId;
      })[0].ponto;
    },
    async salvarItem() {
      if (this.$refs.formAdmin.validate()) {
        this.innerLoading = true;
        const objInsert = {
          id: null,
          clienteId: this.cliente.clienteId,
          servicoId: this.item.servicoId,
          voucherClienteId: null,
          credito: true,
          ativo: true,
          ponto: this.selecionarPontosPorServico(),
          dataLancamento: this.item.data,
          validado: false
        };
        let result = await extratoService.cadastrar(objInsert);
        if (result?.statusCode === 200) {
          this.mensagemAlerta = "Item inserido com sucesso";
          this.mensagemTitulo = "Inserção de extrato";
          this.alertaSucesso = true;
          this.closeModalAdmin();
          this.$refs.formAdmin.resetValidation();
          this.innerLoading = false;
        } else {
          this.mensagemAlerta = result?.data.mensagem;
          this.alertaErro = true;
          this.innerLoading = false;
        }
      }
    },
    validarFiltros() {
      if (
        this.filters.erroDataFinal == null &&
        this.filters.erroDataFinal == null
      ) {
        this.listarItens();
        this.modalFiltro = false;
        this.filters.pontoMinimo = parseInt(this.filters.pontoMinimo);
        this.filters.pontoMaximo = parseInt(this.filters.pontoMaximo);
      }
    },
    async listarItens() {
      this.loading = true;
      let result = await extratoService.listarExtrato(this.filters);
      if (result?.statusCode === 200) {
        this.list = result?.data.lista;
        this.totalPages = result?.data.paginas;
        this.totalItems = result?.data.totalItens;
        this.totalGeral = result?.data.total;
        this.loading = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loading = false;
      }
    },
    closeModalAdmin() {
      this.loading = false;
      this.modalAdmin = false;
      this.item = null;
      const appContainer = document.querySelectorAll(".app-container")[0];
      appContainer.click();
    },
    ocultarSucesso() {
      this.alertaSucesso = false;
      this.loading = false;
      this.listarItens();
    },
    async listarServicos() {
      let result = await servicoService.listarTodosServicos(false);
      if (result?.statusCode === 200) {
        this.listaServicos = result?.data;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
      }
    },
  },
  created: async function () {
    this.fields = await extratoService.listarCampos();
    this.listarItens();
    this.listarServicos();
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
};
</script>

<style scoped>
.grid-component {
  max-height: calc(100vh - 240px) !important;
}
.admin-app-container {
  height: calc(100vh - 120px) !important;
}
@media (max-width: 600px) {
  .grid-component {
    max-height: calc(100vh - 200px) !important;
  }
}
h2 {
  white-space: nowrap;
}
.total-extrato {
  font-size: 1.1rem;
  color: black;
}
</style>